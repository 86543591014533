.modal_fg {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 500px;
  min-height: 200px;
  padding: 20px;
  border-radius: 10px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  // color: $text-color;
  display: block;
  border: solid 1px #ccc;
}

.modal__bg {
  z-index: 9998;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.close {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
