@import "../../../styles/colors.scss";

.iconConfirm {
  font-size: 30px;
  color: #0cdf28;
}

.productModal {
  width: 80vw;
  height: 90vh;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
    overflow-y: scroll;
    border-top: solid 1px #fafafa;
    padding: 20px 10px;
    margin-top: 10px;
  }

  &__images {
    display: flex;
  }

  &__images-menu {
    width: 140px;
    min-width: 140px;
    display: flex;
    flex-direction: column;
  }

  &__images-menu img {
    cursor: pointer;
    border: solid 1px #ccc;
    margin-bottom: 5px;
    padding: 3px;
  }

  &__img {
    padding-right: 10px;
  }
}

.article-col {
  margin-left: 10px;
  color: #686868;
}

.name-row {
  background-color: #fafafa;
}

///////////////////////

.fx {
  display: flex;
}

.fx-wr {
  display: flex;
  flex-wrap: wrap;
}

.fx-im {
  width: 230px;
  min-width: 230px;
  text-align: center;

  img {
    max-width: 200px;
    width: 200px;
  }
}

.fx-all {
  flex-grow: 1;
}

.fx-article {
  border: solid 1px #ccc;
  border-radius: 5px;
  margin: 3px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 180px;
  text-align: center;
}

.fx-article__active {
  border: solid 2px #37d33f;
  // background-color: #f8fff9;

  button {
    color: #37d33f;
  }
}

.fx-article__5 {
  border: solid 2px #d34937;

  button {
    color: #d34937;
  }
}

.fx-article__disable {
  color: #ddd;
}

.hover {
  cursor: pointer;
}
