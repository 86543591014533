@import "../../../styles/colors.scss";

.loading {
  position: absolute;
  top: 0;
  left: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
