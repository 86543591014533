@import "../../../styles/colors.scss";

.chat__wrapper {
  display: flex;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.chat__users {
  min-width: 300px;

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 20px 7px;
  }

  .status-wrap {
    width: 18px;
    display: inline-block;
  }

  .status-user-name {
    flex-grow: 1;
  }

  .status-unread {
    background-color: $alert-color;
    color: #fff;
    border-radius: 5px;
    padding: 0 7px;
  }

  .status-online {
    background: #5dff57;
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }
}

.chat__messages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 0;
}

.chat__messages-box {
  flex-grow: 1;
}

.user {
  padding: 2px 10px;
  display: flex;
  align-items: center;
}

.active-user {
  background-color: #eee;
  border-radius: 5px;
}

.chat-message__wrapper {
  padding: 10px;
  border-radius: 5px;

  max-width: 100% !important;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin-top: 5px;
}

.chat-message__outcome {
  background-color: #dfffdb;
  margin-left: 100px;
}

.chat-message__income {
  background-color: #f9f8ff;
  margin-right: 100px;
}

.chat-message__header {
  color: 777;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
}

.chat__write-box {
  padding: 20px;
}

.chat-date {
  display: inline;
  padding-left: 7px;
  color: #aaa;
  font-weight: normal;
  flex-grow: 1;
}

.read {
  font-weight: normal;
  color: #ccc;
}
