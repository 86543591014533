.sticky-modal__fg {
  position: fixed;
  top: 10px;
  right: 10px;
  min-width: 500px;
  width: 75%;
  bottom: 10px;
  background-color: #fff;
  border-right: solid 1px #ddd;
  z-index: 9001;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
}

.sticky-modal__bg {
  position: fixed;
  top: 56px;
  left: 80px;
  right: 0;
  bottom: 0;
  background-color: #555;
  opacity: 0.7;
  z-index: 9000;
}

.sticky-modal__body-scroll {
  overflow-y: scroll;
}

.sticky-modal__body-scroll::-webkit-scrollbar {
  width: 8px;
}

.sticky-modal__body-scroll::-webkit-scrollbar-track {
  background-color: #fafafa;
}

.sticky-modal__body-scroll::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 8px;
}

.sticky-modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
