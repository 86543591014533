@import "../../styles/colors.scss";

.iconConfirm {
  font-size: 30px;
  color: #0cdf28;
}

.productModal {
  width: 80vw;
  height: 90vh;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
    overflow-y: scroll;
    border-top: solid 1px #fafafa;
    padding: 20px 10px;
    margin-top: 10px;
  }

  &__images {
    display: flex;
  }

  &__images-menu {
    width: 100px;
    display: flex;
    flex-direction: column;
  }
  &__img {
    // min-width: 400px;
    padding-right: 10px;
  }

  .article-list-content {
    margin-left: 200px;
  }

  .articles-list {
    position: absolute;
    width: 180px;
    display: flex;
    flex-direction: column;

    button {
      text-align: left;
      padding: 8px !important;
    }

    .active {
      background-color: #d8f7f7;
    }
  }
}
