.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
  display: flex;
  height: 100vh;
  flex-direction: column;

  justify-content: center;
}

.login-form {
  margin-bottom: 20px;
}
