@import "../../../styles/colors.scss";

.script__wrapper {
  display: flex;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.script-dialog {
  overflow-y: scroll;
  padding: 10px;
  height: 100%;
}

.script-steps {
  min-width: 300px;
  overflow-y: auto;
}

.script-step-menu {
  display: flex;
  flex-direction: column;
}

.script-body {
  font-size: 1.6rem;
  padding: 20px;
  margin-bottom: 10px;
}

.script-saved-content {
  margin-right: 40px;
  background-color: #e3eaee;
  border-radius: 5px;
  padding: 5px 10px;
}

.script-saved-answer {
  text-align: right;
}
