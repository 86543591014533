.side-menu {
  position: fixed;
  top: 56px;
  left: 0;
  bottom: 0;
  width: 80px;
  background: #1b2733;
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px;
  
}

.side-menu a {
  color: #868b91;
  text-align: center;
  padding: 20px 0;
  margin: 0 5px ;
  font-size: 0.8rem;
}

.side-menu a:hover {
  color: #fff;
}

.side-menu a.active {
  background-color: #009e74;
  color: #fff;
  border-radius: 5px;
}

.side-menu svg {
  display: inline-block;
  width: 100%;
  font-size: 1.8rem;
}

