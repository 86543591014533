@import "./styles/colors.scss";

.subheader {
  position: fixed;
  right: 0;
  left: 80px;
  padding: 14px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
}

.subheader-with-sidebar {
  position: fixed;
  right: 0;
  left: 375px;
  padding: 14px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
  z-index: 2;
}


.content {
  margin: 55px 15px 0 85px;
  box-sizing: content-box;
}

a {
  text-decoration: none !important;
}

h1 {
  // padding-top: 30px;
  // padding-left: 20px;
  font-size: 38px !important;
}

.page-with-sidebar {
  background-color: #eaeef6;
  min-height: calc(100vh - 50px);
  margin: 0 -15px 0 0;
  padding: 0px 30px;
  position: relative;
}

.col-sidebar {
  width: 270px !important;
  margin: 0;
}

.sidebar-fixed {
  position: fixed;
  width: 280px;
  padding-top: 0px;
  padding-left: 2px;
  top: 55px;
  bottom: 0;
  left: 80px;
  border-right: solid 1px #eee;
  background-color: #fff;
}

.btn-link {
  padding-left: 0 !important;
  text-align: left;
  text-decoration: none !important;
  outline: none !important;
}

.btn-link:focus {
  box-shadow: none !important;
}

.btn-link svg {
  margin-right: 5px;
}

.sidebar-scroll {
  overflow-y: auto;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 8px;
}

// .sidebar-scroll::-webkit-scrollbar-track {
//   // background: orange;
// }

.sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.nav-group {
  font-weight: 500;
  a {
    color: #192e43;
  }
}

.wrapperPageHeader {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.col-sidebar-clients {
  width: 320px;
}

.react-datepicker__input-container {
  padding-right: 5px;
}

.nav-count-status {
  display: block;
  padding: 0.5rem 1rem;
  color: #777;

  font-size: 15px;
}
.nav-count-status-alert {
  display: block;
  padding: 0.3rem 0.9rem;
  margin: 0.2rem 0;
  color: #fff;
  font-size: 14px;
  background-color: $alert-color;
  border-radius: 5px;
}

.nav-count-status-warning {
  display: block;
  padding: 0.3rem 0.9rem;
  margin: 0.2rem 0;
  color: #fff;
  font-size: 14px;
  background-color: $warning-color;
  border-radius: 5px;
}

.page-message-empty {
  text-align: center;
  margin-top: 200px;
  color: #777;
}

.item-active {
  border-radius: 5px;
  padding: 0 10px;
  margin-left: -10px;
  margin-right: -10px;
  background-color: #f2f6f9;
}

.wi-50 {
  width: 50px;
}

.wi-100 {
  width: 100px;
}

.wi-150 {
  width: 150px;
}

.wi-200 {
  width: 200px;
}

.wi-250 {
  width: 250px;
}

.wi-400 {
  width: 400px;
}

.wi-350 {
  width: 350px;
}

.userName {
  color: #aaa;
  font-size: 14px;
}

.client-active {
  background-color: #e2f0fa;
}

$primary-color: #f65261;
$secondary-color: #424242;
$dark-color: #232323;
$background-color: #555;
$light-color: #fff;
$text-color: #ccc;
$green-color: #18d642;

.Toastify__toast {
  border-radius: 5px !important;
  padding-left: 15px !important;
}

.icon-l {
  font-size: 2rem;
}

.wr-block * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-mini {
  padding: 0 !important;
}

.btn-nav {
  padding: 4px !important;
}



.timeline-item {
  position: relative;
  
}

.timeline-item::before {
  background-color: #eee;
  content: '';
  position: absolute;
  left: 180px;
  width: 4px;
  height: 100%;
}


.timeline-event {
  padding: 20px 0 20px 230px;
}

.timeline-date {
  position: absolute;
  padding: 20px ;
  text-align: center;
}

.timeline-date small{
  color: #aaa;
  display: block;
}

.timeline-item .circle {
  // background-color: #fff;
  border: 4px solid #eee;
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  left: 162px;
  top: calc(50% - 20px);
  width: 40px;
  z-index: 100;
  text-align: center;
  color: #aaa;
  font-size: 1.1rem;
}

.overflow {
  overflow: hidden;
}

dt  {
  clear:left;
  float:left;
  width:100px;
  font-weight: 500;
}

dd {
  clear:right;
  padding: 0px 0 0px 20px;
  font-weight: 400;
}


.card1 {
  background-color: #fefefe;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
 

  &-body {
    padding: 12px 30px 30px;
    color: #2f4154;
  }

  &-header {
    font-size: 1.5rem;
    font-weight: 500;
    color: #2c5d91;
    padding: 14px 26px;
    border-bottom: 1px solid #eceef1 !important;
    border-radius: 10px 10px 0 0;
  }

  &-header svg{
    margin-right: 8px;
  }

}

.footer-notification {
  position: fixed;
  bottom: 0;
  left: 80px;
  right: 0px;
  background-color: #ffbdbd;
  z-index: 100;
  padding: 16px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.footer-notification__logo svg{
  font-size: 2.2rem; 
  color: #ca3636;
}

.footer-notification a{
  margin:  0px 20px !important;
  color: #ca3636;
  text-decoration: underline !important;
}