@import "../../../styles/colors.scss";

.call-outgoing {
  color: #2dc95c;
  svg {
    margin-right: 10px;
  }
}

.call-incoming {
  color: #ff4e4e;
  svg {
    margin-right: 10px;
  }
}

.cdr-listen {
  svg {
    margin-right: 10px;
  }
}

.scrolled {
  position: absolute;
  overflow-y: auto;
  flex-grow: 1;
  top: 200px;
  bottom: 150px;
  width: 710px;
}

.react-audio-player {
  position: fixed;

  left: 150px;
  //width: 600px;
  width: calc(100% - 220px);

  bottom: 20px;
  outline: none;
  border: solid 1px #d2d8db;
  border-radius: 5px;
  background-color: #f1f3f4;
}

.active-row {
  background-color: #eff3fd;
}
