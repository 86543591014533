@import "../../styles/colors.scss";

.navbar {
  background-color: #18323a;
  color: #fff;
  height: 60px;
  padding-left: 70px;

  .nav-item {
    padding: 0 10px;
    position: relative;
  }

  .marker-alert {
    position: absolute;
    top: 0px;
    right: 0px;
    color: $alert-color;
  }

  .marker-warning {
    position: absolute;
    top: 0px;
    right: 0px;
    color: $warning-color;
  }
}
