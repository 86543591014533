@import "../../styles/colors.scss";

.btn-phone {
  width: 100px;
  height: 80px;
  margin: 5px;
  font-size: 50px;
  line-height: 50px;
}

.phone-content {
  flex-grow: 1;
}

.phone-status {
  padding: 5px 30px 15px;
}

.btn-phone-control {
  width: 230px;
  height: 60px;
  margin: 10px;
  font-size: 20px;
}

.control-panel {
  position: fixed;
  bottom: 20px;
  left: 90px;
  background-color: $header-color;
  border-radius: 40px;
  color: #fff;
  padding: 10px 14px 10px 20px;
  display: flex;
  align-items: center;

  a,
  a:hover {
    color: #fff;
  }
}

.btn-control-panel {
  border-radius: 50% !important;
}
