.changeCount {
  outline: 0 !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 5px !important;
  color: #d1d9e6 !important;
  font-size: 1.3rem !important;
  line-height: 1rem !important;
}

.row-summary td {
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  border-top: none;
}

.btn-add-in-order {
  margin: 0 10px;
}
