@import "../../styles/colors.scss";

.order-page {
  background-color: #f7f9fc;
  min-height: calc(100vh - 50px);
  margin: 0 -15px;
  padding: 15px 30px;
}

.order-body {
  display: flex;
}

.order-params svg {
  fill: #616161;
}

.order-status-list {
  padding-left: 7px;
}

.card-order {
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e9ebef;
  //border-top: solid 1px #f3f3f3;
  margin: 10px 0;

  padding: 20px;

  // border-left: solid 5px #ff8585;
}

.card-order svg {
  margin-right: 5px;
}

.card-order .btn {
  margin-right: 5px;
  margin-bottom: 5px;
}

.last-edited {
  border: solid 5px #e9ebef !important;
  margin-left: -4px;
  margin-right: -4px;
}

.order-status {
  border-radius: 3px;
  padding: 2px 7px;
  font-size: 1rem;
}

.order-cancel {
  .order-status {
    background-color: #b4b4b4;
    color: #fff;
  }

  background: linear-gradient(to bottom right, #ffffff, #fbfbff);
}

.order-wait {
  .order-status {
    background-color: $alert-color;
    color: #fff;
  }
}

.order-draft {
  .order-status {
    background-color: #505050;
    color: #fff;
  }
  background-color: #fdfdfd;
}

.order-wait-date-reserve {
  .order-status {
    background-color: #d1ecf1;
    color: #0c5460;
  }
}

.order-wait-reserve {
  .order-status {
    background-color: #ffea31;
    color: #fff;
  }
}

.order-send {
  .order-status {
    background-color: #26ff6e;
    color: #fff;
  }
}

.order-info {
  width: 140px;
  display: flex;
  flex-direction: column;
}

.order-content {
  width: 50%;
}

.order-footer {
  // border-top: solid 1px #eee;
  // border-bottom: solid 1px #eee;
  padding: 1px;
  margin-bottom: 20px;
}

.order-action {
  padding: 10px 17px;
}

.orderModal {
  width: 80vw;
  height: 90vh;
  padding: 0px;
  display: flex;
  flex-direction: column;

  &__header {
    font-weight: bold;
    padding: 0 30px;
  }

  &__content {
    flex-grow: 1;
    // border-top: solid 1px #ccc;
    margin-top: 10px;
    overflow-y: scroll;
    padding: 10px 30px;
  }

  &__client {
    // width: 50%;
    padding: 0 30px;
    line-height: 2.5rem;
  }

  &__summary {
    //   width: 50%;
    text-align: right;
    padding: 0 30px;
    line-height: 2.5rem;
  }

  .price-block {
    display: inline-block;
    width: 180px;
  }
  // &__description {
  //   width: 70%;
  //   // overflow-y: scroll;
  //   border-right: solid 1px #ccc;
  //   padding: 20px 10px;
  //   margin-top: 10px;
  // }

  // &__history {
  //   width: 30%;
  //   overflow-y: scroll;
  //   // border-top: solid 1px #fafafa;
  //   padding: 20px 10px;
  //   margin-top: 10px;
  // }
}

.timeline {
  display: flex;
}

.booked {
  color: #3bec01;
}

.un-booked {
  color: #ff4e4e;
}

.f-size-l {
  font-size: 1.6rem;
}

.order-content-unavailable {
  background-color: #ff4e4e;
  color: #fff;
  padding: 0 10px;
}

.order__user {
  position: relative;
  background: #e9ebef;
  margin: -20px auto 10px;
  max-width: 400px;
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.order__user .btn-link {
  margin: 0;
  color: #505050;
}
